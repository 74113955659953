<section class="static-content">

    <div class="static-header">
        <div class="container">
            <div class="header-inner" fxLayout="row" fxLayoutAlign="start center">
                <div class="static-brand">
                    <a href="https://www.empirxhealth.com" target="_blank" rel="noopener noreferrer">
                        <img class="brand" src="../../../../assets/images/brand-logo/logo.svg" width="95"
                            alt="EmpiRx"></a>
                </div>
                <h3 class="static-title">Terms of Use</h3>
            </div>

            <mat-divider></mat-divider>
        </div>
    </div>

    <div class="container">
        <div class="static-text">
            <p>These Terms of Use apply to registered users who use the EmpiRx Health Reporting Portal (the
                “Portal”) available through <a href="https://www.empirxhealth.com/" target="_blank"
                    rel="noopener noreferrer">www.empirxhealth.com</a> (the "Website"). The Website and
                Portal are provided to you as a service to provide access to information, reports and data related to
                your account for the specific health plan client(s) you represent (“Client”). The Portal is a registered
                user-only account portal that enables Users to access only the applicable Client account information.
            </p>

            <p>"Users" are persons utilizing the Website and Portal and include employees, agents and contractors of the
                health plan Client or the broker, advisor, or consultant of the health plan Client. "Registered Users"
                are eligible individuals who have registered for use of the Website and Portal and who represent and
                warrant that they are authorized by the EmpiRx health plan Client to access information, including
                Protected Health Information as defined under HIPAA, about the health plan and its pharmacy claims
                expenses, members’ prescription and medical claims data, rebates, and benefit information. You agree
                that health plan Client has given you, as an employee, contractor, agent, broker or consultant,
                authority to view information concerning the health plan’s pharmacy benefit solely for the purpose of
                providing analysis, services and support of such health plan Client and for no other purpose. If you are
                a health plan client of EmpiRx, you are also subject to the terms and conditions of the pharmacy benefit
                agreement between us. </p>
        </div>

        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        Consent
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>These Terms of Use are between a User of any portion of the Website and Portal ("you" or "your")
                        and
                        EmpiRx Health, the pharmacy benefit manager of the applicable Client. </p>
                    <p>You are deemed to have assented to these Terms of Use when you use any available page of the
                        Website or
                        Portal; each time you access the Website or Portal; and each time you use your login credentials
                        to
                        access the Portal portion of the Website. You are deemed to have accepted these Terms of Use
                        each time
                        you use non-login Website functionality. By registering for or otherwise accessing or using the
                        Website,
                        you acknowledge that you have read, understand, and agree to be legally bound by these Terms of
                        Use.
                    </p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        Updates
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>We may modify or amend the Website or Portal at any time and remove or add new features or
                        functionality.
                        Access to and use of these features will remain subject to these Terms of Use and the Privacy
                        Statement,
                        each as may be amended from time to time. These Terms of Use and the <a href="/privacyStatement"
                            target="_blank" rel="noopener noreferrer">Privacy
                            Statement</a> are
                        available
                        during registration for the Website and on various pages of the Website.</p>
                    <p>We will provide an advance notice of a major change prior to your access of any portion of the
                        Website or
                        Portal for which registration is required. For example, we may (i) require that you reaccept the
                        updated
                        version of the web policies, (ii) send an electronic notification advising of the update to the
                        web
                        policies, (iii) include a notice on the Website viewable without login advising of the update to
                        the web
                        policies, and/or (iv) advise of the updated web policies during a phone call. We do not
                        ordinarily
                        provide advance notice of a minor change.
                    </p>
                    <p>It is important that you check these Terms of Use every time you visit the Website or Portal.
                        Your use of
                        the Website or Portal and/or utilization of any Website or Portal benefits after the Terms of
                        Use have
                        been updated (and after advance notice of certain major changes), indicates your agreement and
                        acceptance of the updated version of the Terms of Use and the <a href="/privacyStatement"
                            target="_blank" rel="noopener noreferrer">Privacy Statement</a>,
                        including the
                        modifications made as of the date of your use.</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        Privacy Statement
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>You agree to the <a href="/privacyStatement" target="_blank" rel="noopener noreferrer">Privacy
                            Statement</a> , which is incorporated by reference in
                        these
                        Terms of Use</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        User Obligations
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>You are required to comply with all applicable laws in connection with your use of the Website
                        and the
                        Portal. As a condition of your use of the Website and Portal, you agree that you will not use
                        the
                        Website or Portal for any purpose that is unlawful or prohibited by these Terms of Use. You
                        agree that
                        you will only provide information during registration and in connection with any and all other
                        uses of
                        the Website that is true and accurate and is not false, misleading, or otherwise an
                        impersonation of any
                        person or entity. </p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        Website and portal content and intellectual property rights
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>This Website and Portal may contain text, images, software (including images or files
                        incorporated in or
                        generated by the software or data accompanying such software), photographs, video, graphics,
                        audio,
                        features, data, designs, images, proprietary financial terms and analyses, and other such
                        similar
                        content (collectively "Website Content"). Unless otherwise expressly identified, Website Content
                        is
                        owned by us or by our third-party licensors. The Website Content is protected by United States
                        and
                        international copyright, trademark, and other laws. You may browse the Website and download
                        Website
                        Content solely for your personal use, provided you keep intact all copyright and other
                        proprietary
                        notices. Except as expressly permitted, you may not modify, copy, reproduce, republish, upload,
                        post,
                        transmit, hyperlink to or from, or distribute in any way Website Content from this Website,
                        including
                        code and software underlying this Website, nor may you sell, transfer, or otherwise use the
                        Website or
                        the Website Content in commerce or for any public or commercial endeavor without our prior and
                        express
                        written consent. We may in our sole discretion make changes to the Website Content at any time
                        without
                        notice.</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        SMS Text communications
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>We support SMS texting communications in accordance with the communication preferences that are
                        available
                        to you and for informational and/or marketing messages associated with certain other EmpiRx
                        Health text
                        message programs. If you opt-in to receive text messages for a particular category of
                        communication
                        preferences, you will receive text messages consistent with your communication preferences as
                        further
                        described in the <a href="/privacyStatement" target="_blank" rel="noopener noreferrer">Privacy
                            Statement</a>. </p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        No Medical Advice
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>The information provided on the Website and in the Portal is for informational purposes or
                        general
                        guidance only and does not constitute medical or other professional advice. We do not warrant or
                        guarantee the accuracy, completeness, adequacy, or currency of the provided information, nor do
                        we
                        endorse any views or products. Information on the Website and Portal is not a substitute for
                        medical or
                        other professional advice, and it is important that no medical or other professional decisions
                        should be
                        made without first consulting a personal physician, pharmacists or other applicable
                        professional. </p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        Use Restrictions
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>You agree that you will not (and will not attempt to): (a) access or use the Website or Portal
                        thereof in
                        any manner or for any purpose not expressly authorized by these Terms of Use; (b) distribute,
                        sublicense, lease, rent, loan, or otherwise use or make any component of the Website or Portal
                        available
                        for use by third parties, including as part of a service bureau, outsourcing, or external
                        consulting
                        arrangement; (c) allow any other person or entity to use your login credentials to access, view,
                        or use
                        any component of the Website or Portal or for posting, copying, extracting, downloading,
                        viewing,
                        transmitting, or receiving data of any kind; (d) access any component available through the
                        Website or
                        Portal via remote access through interfaces or automated means not approved in writing by us;
                        (e)
                        collect, compile, or otherwise attempt to "screen scrape," "data mine" or "harvest" any
                        component of the
                        Website or Portal, including through the creation of any duplicate or derivative data store; (f)
                        deactivate, bypass, or circumvent any access controls or security measures for the Website or
                        Portal;
                        (g) gain unauthorized access to the Website or Portal or another site user's network, systems,
                        or data;
                        (h) engage in any activity or use any device, software, or routine, or introduce any virus,
                        Trojan
                        horse, spyware, adware, or other malicious code, that interferes with a user's access to the
                        Website or
                        Portal or the proper operation of this Website and Portal; (i) engage in any activity that
                        disrupts or
                        impairs the performance of the Website or Portal; (j) access or use the Website or Portal in
                        violation
                        of any applicable laws or regulations, the intellectual property or other rights of any third
                        party, or
                        any contractual or legal duty or obligation including in a manner prohibited by these Terms of
                        Use; (k)
                        reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code or
                        underlying
                        programming, models, databases, or database structures for the Website or Portal; or (l) remove
                        or
                        obscure any copyright, trademark, confidentiality, or other Website or Portal notices, terms, or
                        disclosures. We reserve the right to suspend or terminate Website or Portal access to address
                        non-compliance with these Terms of Use.</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        Disclaimer of Warranties
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>THE WEBSITE, WEBSITE CONTENT AND PORTAL ARE PROVIDED ON AN "AS IS BASIS, AND ALL USE OF THE
                        WEBSITE AND
                        THE WEBSITE CONTENT IS AT YOUR OWN RISK. TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW,
                        WE MAKE
                        NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, REGARDING THE WEBSITE, WEBSITE
                        CONTENT, PORTAL OR YOUR ACCESS TO OR USE THEREOF. WE HEREBY DISCLAIM ANY AND ALL IMPLIED AND
                        STATUTORY
                        WARRANTIES OF ANY KIND, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, FITNESS
                        FOR A
                        PARTICULAR PURPOSE, PERFORMANCE, TITLE, QUIET ENJOYMENT, NON-INFRINGEMENT, CUSTOM, AND USAGE OF
                        TRADE.
                        WITHOUT LIMITING THE FOREGOING, WE DO NOT REPRESENT OR WARRANT THAT THE WEBSITE, THE WEBSITE
                        CONTENT OR
                        PORTAL WILL BE ACCURATE, COMPLETE, UP-TO-DATE, OR RELIABLE; THAT ACCESS TO OR USE OF THE WEBSITE
                        OR
                        PORTAL WILL BE UNINTERRUPTED, SECURE, OR ERROR-FREE; THAT THE WEBSITE, WEBSITE CONTENT, PORTAL
                        OR ANY
                        PORTION THEREOF WILL NOT CHANGE OR BE DISCONTINUED; THAT ERRORS OR DEFECTS WILL BE CORRECTED;
                        THAT THE
                        WEBSITE, WEBSITE CONTENT OR PORTAL WILL BE FREE FROM COMPUTER VIRUSES OR OTHER HARMFUL
                        COMPONENTS; OR
                        THAT ACCESS TO OR USE OF THE WEBSITE, THE WEBSITE CONTENT OR PORTAL IS LAWFUL IN ANY PARTICULAR
                        JURISDICTION. NO SUPPLIER TO US HAS ANY WARRANTY, SUPPORT, OR OTHER OBLIGATIONS TO YOU IN
                        CONNECTION
                        WITH YOUR USE OF THIS WEBSITE. YOU ASSUME THE ENTIRE OBLIGATION AND COST OF ANY AND ALL
                        NECESSARY
                        COMPUTER, MOBILE DEVICE, AND NETWORK SERVICING, REPAIR, AND CORRECTION. IF YOU ARE DISSATISFIED
                        WITH THE
                        WEBSITE, WEBSITE CONTENT, OR PORTAL YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE
                        WEBSITE.
                        SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR CERTAIN OTHER WARRANTIES, SO THE
                        ABOVE
                        DISCLAIMERS MAY NOT APPLY TO YOU TO THE EXTENT SUCH A JURISDICTION'S LAW APPLIES AND LIMITS SUCH
                        DISCLAIMERS.</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        Limitation of Liability
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
                        EXEMPLARY, PUNITIVE, OR OTHER DAMAGES OR LOSSES OF ANY KIND ARISING OUT OF OR DIRECTLY OR
                        INDIRECTLY
                        RELATED TO THE WEBSITE, THE WEBSITE CONTENT, PORTAL, YOUR ACCESS TO OR USE THEREOF, OUR
                        PERFORMANCE,
                        NON-PERFORMANCE, ACTS, OR OMISSIONS IN CONNECTION THEREWITH, THE <a href="/privacyStatement"
                            target="_blank" rel="noopener noreferrer">PRIVACY
                            STATEMENT</a>, OR THESE
                        TERMS OF
                        USE, EVEN IF FORESEEABLE, AND EVEN IF WE HAVE BEEN ADVISED OR SHOULD HAVE KNOWN OF THE
                        POSSIBILITY OF
                        SUCH DAMAGES. IN ANY EVENT, UNDER NO CIRCUMSTANCES SHALL OUR TOTAL, CUMULATIVE LIABILITY TO YOU
                        FOR ANY
                        AND ALL DAMAGES, LOSSES, CLAIMS, AND CAUSES OF ACTION EXCEED TWENTY-FIVE DOLLARS ($25.00). IN NO
                        WAY
                        LIMITING THE FOREGOING, WE ASSUME NO OBLIGATION AND SHALL HAVE NO LIABILITY ARISING OUT OF OR
                        DIRECTLY
                        OR INDIRECTLY RELATED TO ANY COMMUNICATION ERRORS, SERVICE INTERRUPTIONS, OR WEBSITE
                        MALFUNCTIONS; THE
                        CONDUCT OR CONTENT OF ANY WEBSITE USER; COMPUTER VIRUSES OR OTHER HARMFUL COMPONENTS; LOSS OR
                        CORRUPTION
                        OF DATA; DAMAGE TO YOUR COMPUTER, MOBILE DEVICE, OR NETWORK; MALICIOUS ACTS OF THIRD PARTIES;
                        FORCE
                        MAJEURE EVENTS; BUSINESS INTERRUPTION OR DOWNTIME; LOSS OF BUSINESS, PROFITS, OR GOODWILL; OR
                        ACTIONS
                        TAKEN OR NOT TAKEN IN CONNECTION WITH INVESTIGATIONS, DEMANDS, OR CLAIMS BY US, INTELLECTUAL
                        PROPERTY
                        OWNERS, LAW ENFORCEMENT, GOVERNMENTAL AUTHORITIES, OR THIRD PARTIES. THE LIMITATIONS ON
                        LIABILITY IN
                        THIS SECTION SHALL APPLY TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, REGARDLESS OF
                        THE
                        NUMBER OF INCIDENTS OR CLAIMS, AND REGARDLESS OF THE NATURE OF THE DAMAGE, LOSS, CLAIM, OR CAUSE
                        OF
                        ACTION (WHETHER IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, INDEMNITY, OR OTHERWISE). THE
                        LIMITATIONS ON LIABILITY IN THIS SECTION ARE AN ESSENTIAL PART OF THESE TERMS OF USE, AND SHALL
                        BE VALID
                        AND BINDING EVEN IF ANY REMEDY IS DEEMED TO FAIL OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO
                        NOT
                        ALLOW THE EXCLUSION OF INCIDENTAL, CONSEQUENTIAL, OR CERTAIN OTHER DAMAGES, SO THE ABOVE
                        EXCLUSIONS MAY
                        NOT APPLY TO YOU TO THE EXTENT SUCH A JURISDICTION'S LAW APPLIES AND LIMITS SUCH EXCLUSIONS.</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        Notice of Copyright infringement
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>If you believe that your work has been copied and is accessible on the Website in a way that
                        constitutes
                        copyright infringement, please provide our designated Copyright Agent identified below with the
                        following information:</p>

                    <ul>
                        <li>identification of the copyrighted work claimed to have been infringed</li>
                        <li>identification of the allegedly infringing material on the Website that is requested to be
                            removed
                        </li>
                        <li>your name, address and daytime telephone number, and an e-mail address if available, so that
                            we may
                            contact you if necessary</li>
                        <li>a statement that you have a good faith belief that the use of the copyrighted work is not
                            authorized
                            by the copyright owner, its agent, or the law</li>
                        <li>a statement that the information in the notification is accurate, and under penalty of
                            perjury, that
                            the signatory is authorized to act on behalf of the owner of an exclusive copyright right
                            that is
                            allegedly infringed</li>
                        <li>a physical or electronic signature of the copyright owner or the person authorized on the
                            owner's
                            behalf to assert infringement of copyright and to submit the statement.</li>
                    </ul>

                    <p>Contact Information for Our Copyright Agent: via email: <a
                            href="mailto:privacy@empirxhealth.com">privacy@empirxhealth.com</a> or via mail:
                        EmpiRx
                        Health, LLC, ATTN: Privacy Officer, 155 Chestnut Ridge Road, Montvale, NJ 07645.</p>
                    <p>We will remove any content that infringes the copyright of any person under the laws of the
                        United States
                        upon receipt of information as set forth in Subsections (a) - (f) above, and may, in our sole
                        discretion
                        if we deem it appropriate, terminate access rights of any user who we reasonably believe
                        violates any
                        United States copyright laws.</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        Governing Law
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>These Terms of Use shall be governed by and construed in accordance with the laws of the State of
                        New
                        Jersey, excluding its conflict of law rules.</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        Jurisdiction; Venue
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>Any dispute, claim, action or proceeding arising out of or related to the Website, Website
                        Content, the
                        <a href="/privacyStatement" target="_blank" rel="noopener noreferrer">Privacy Statement</a>, or
                        these Terms of Use, or the interpretation or enforcement
                        hereof, whether
                        at law or
                        in equity, shall be brought only in the state courts located in Bergen County, New Jersey or, if
                        proper
                        and exclusive federal subject matter jurisdiction exists, the District Court of New Jersey in
                        Newark,
                        New Jersey. Each party hereby submits to the personal jurisdiction and consents to the exclusive
                        venue
                        of such courts and waives any objections thereto. Notwithstanding the foregoing, in connection
                        with
                        asserting or protecting our intellectual property or other legal rights or business interests,
                        we retain
                        the right to seek temporary or permanent injunctive or other non-monetary equitable relief
                        against you
                        in any court of competent jurisdiction.
                    </p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        No Waiver
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>Any waiver by us must be express and in writing, must be directed specifically by us to you, and
                        must be
                        signed by our duly authorized representative. Our failure to enforce any provision of these
                        Terms of Use
                        or to respond to a breach by you or third parties shall not in any way limit or waive our right
                        to do
                        so, including without limitation our right to enforce subsequently any provision of these Terms
                        of Use,
                        or to assert our rights with respect to the same or similar breaches.</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">
                        Entire Agreement
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>Unless otherwise specified herein, these Terms of Use and the <a href="/privacyStatement"
                            target="_blank" rel="noopener noreferrer">Privacy Statement</a>
                        incorporated
                        herein
                        constitute the entire agreement between you and us with respect to the subject matter hereof,
                        and
                        supersede all prior and contemporaneous agreements and understandings, whether written or oral,
                        concerning the subject matter hereof. </p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="static-subtitle">Severability</mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>If any portion of these Terms of Use or the <a href="/privacyStatement" target="_blank"
                            rel="noopener noreferrer">Privacy Statement</a> is held invalid or
                        unenforceable
                        under
                        applicable law, that portion shall be construed in a manner consistent with applicable law to
                        accomplish, as nearly as possible, the objective thereof, or severed from the document if and
                        solely to
                        the limited extent such construction is not possible, and the remaining portion of these Terms
                        of Use
                        and the <a href="/privacyStatement" target="_blank" rel="noopener noreferrer">Privacy
                            Statement</a> shall remain in full force and effect.</p>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

    </div>
</section>
<div class="footer text-center">
    <app-footer [isDownload]="true"></app-footer>
</div>