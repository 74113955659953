import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/app.constants';
import { SharedService } from '../../../services/shared/shared.service';
import { AuthService } from '../../services/auth/auth.service';
import { EligibilityService } from '../../services/eligibility/eligibility.service';

@Component({
  selector: 'app-app-selector',
  templateUrl: './app-selector.component.html',
  styleUrls: ['./app-selector.component.scss']
})
export class AppSelectorComponent implements OnInit, OnDestroy {
  public constants = AppConstants;

  public subscription: Subscription = new Subscription();
  public hasReportAccess: boolean = localStorage.getItem(AppConstants.LABELS.hasReportsAccess) === 'YES';

  constructor(private readonly router: Router) { }

  ngOnInit(): void {
    // this.eligibility.getClientDetails().subscribe((res) => console.log(res));
  }

  public navigateToReports(): void {
    this.router.navigate(['/main']);
  }

  public navigateToManagement(): void {
    this.router.navigate(['management/copay-schedule']);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
