import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UtilService } from '../../services/util/util.service';
import { IMainMenu, MenuItem } from '../../interfaces/menu.interface';
import { AppConstants } from 'src/app/app.constants';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { BenefitManagementService } from 'src/app/core/services/benefit-management/benefit-management.service';
import { Observable, of } from 'rxjs';
import { SharedService } from '../../services/shared/shared.service';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  public accessRoles: IMainMenu[];
  public selectedMenu: string = 'Co-pay Schedule';
  public selectedMainMenu: string = 'Benefit Tools';
  public showSubMenus = true;
  isInternalUser$: Observable<boolean> = of(false);

  constructor(
    public readonly router: Router,
    private readonly utilService: UtilService,
    private authService: AuthService,
    private benefitService: BenefitManagementService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    let accessLevel;
    this.sharedService.accessLevel$.subscribe((v) => {
      accessLevel = v;
      this.accessRoles = this.utilService.getAccessRoles(accessLevel ? accessLevel : 'Level A');
    })
    this.isInternalUser$ = this.benefitService.isInternalUser();
    if (window.location.pathname.includes('audit-log')) {
      this.selectedMainMenu = 'Audit Logs';
    }
  }

  public clickMainMenu(menuItem: IMainMenu): void {
    if (menuItem.name) {
      this.selectedMainMenu = menuItem.name;
      if (this.selectedMainMenu === 'Benefit Tools') {
        this.showSubMenus = !this.showSubMenus;
        this.selectedMenu = 'Co-pay Schedule';
        this.router.navigateByUrl('/management/copay-schedule');
      }
      if (menuItem.route) {
        this.router.navigateByUrl(menuItem.route);
      }
    }
  }

  // get isInternalUser(): boolean {
  //   if (this.authService.userEmail.split('@')[1] == 'empirxhealth.com') {
  //     return true;
  //   }
  //   return false;
  // }

  get redirectToTredium(): string {
    return environment.trediumUrl;
  }

  public clickMenu(menu: MenuItem): void {
    this.selectedMainMenu = 'Benefit Tools';
    this.selectedMenu = menu.name;
    this.router.navigateByUrl(menu.route);
  }

  public goToHome() {
    this.router.navigateByUrl('/app');
  }
}
