<main class="main-page full-height overflow-hidden" fxLayout="column">
  <div class="top-header">
    <app-header [showSideBarMenu]="false" [showClientName]="false"></app-header>
  </div>
  
  <div class="dashboard-content full-height overflow-hidden" style="padding: 4rem" fxLayout="column">
    <div class="dashboard-wrapper full-height" fxLayout="row" style="background: none">
      <div fxLayout="row" class="full-width">
        <div class="welcome-contnet" fxLayoutAlign="left center">
          <div class="hero-text hero-text-full">
            <h1>
              <span>{{ constants.LABELS.welcome }} </span>
            </h1>
            <p>{{constants.ERROR.MESSAGES.DESCRIPTION}}</p>
          </div>
        </div>
        <div class="welcome-buttons" fxLayout="column" fxLayoutAlign="Left center" style="min-height: 150px; overflow: auto;justify-content: flex-start!important;">
          <div class="center loader" *ngIf="isLoading">
            <img src="../../../../assets/images/loader.gif" alt="loader" class="loader-img" />
          </div>
          <section *ngIf="listOfParentClients.length > 0">
            <h4 class="text-label">{{constants.LABELS.combinedReport}}</h4>
            <div class="input-group">
              <div fxLayout="row layout-wrap" fxLayoutAlign="start start">
                <div fxFlex="30" *ngFor="let p of listOfParentClients">
                  <mat-checkbox [checked]="p.checked" (change)="clickedCombinedReport($event,p)">
                    {{p.clientName}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </section>
          <div *ngIf="listOfParentClients.length > 0" class="or text-accent"><span>{{constants.LABELS.or}}</span>
          </div>
          <section *ngIf="listOfClients.length > 0">
            <h4 class="text-label">{{constants.LABELS.individualClient}}</h4>
            <div class="input-group">
              <div fxLayout="row layout-wrap" fxLayoutAlign="start start">
                <div fxFlex="30" *ngFor="let c of listOfClients">
                  <mat-checkbox [checked]="c.checked" (change)="checkedIndividualClientReport($event,c)">
                    {{c.clientName}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </section>
          <div class="or text-accent" *ngIf="showDiversifiedClients"><span>{{constants.LABELS.or}}</span></div>
          <section *ngIf="showDiversifiedClients">
            <h4 class="text-label">Report by Diversified Client</h4>
            <div class="input-group">
              <div fxLayout="row layout-wrap" fxLayoutAlign="start start">
                <div fxFlex="30" *ngFor="let d of diversifiedClientList">
                  <mat-checkbox [checked]="d.checked" (change)="checkedDiversifiedClient($event,d)">
                    {{d.subGroupName}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </section>
          <div *ngIf="showError">
            <h6>{{constants.ERROR.MESSAGES.NOCLIENTS}}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bottom-panel text-center">

    <div class="btn-group flex justify-center" *ngIf="!showError">
      <button mat-button class="secondary-btn sm-btn" *ngIf="showBackIcon" (click)="backToAppSelection()"> Back to
        Home</button>
      <button mat-button class="primary-btn sm-btn" (click)="saveReportDetails()"
        [disabled]="isDisabledButton()">{{constants.LABELS.continueReport}}</button>
    </div>
  </div>
  <div class="footer text-center">
    <app-footer></app-footer>
  </div>
</main>


