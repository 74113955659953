import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, defer, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { EligibilityService } from 'src/app/core/services/eligibility/eligibility.service';
import {
  CapDeductibleAccumulation,
  CapDeductibleRequestInterface,
} from 'src/app/interfaces/cap-deductible-accumulation.interface';

import { CapDeductiblesResponse } from 'src/app/interfaces/cap-deductibles-response.interface';
import { MemberDetails } from 'src/app/interfaces/member.interface';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { LoaderService } from '../../services/loader/loader.service';
import { AppConstants } from '../../../app.constants';
import { BenefitManagementService } from 'src/app/core/services/benefit-management/benefit-management.service';
import { SharedService } from '../../../services/shared/shared.service';

@Component({
  selector: 'app-cap-deductible-moop',
  templateUrl: './cap-deductible-moop.component.html',
  styleUrls: ['./cap-deductible-moop.component.scss'],
})
export class CapDeductibleMoopComponent implements OnInit {
  deductible: boolean = false;
  cap: boolean = false;
  moop: boolean = false;
  displayedColumnsDeductible: string[] = [
    'Phase',
    'Frequency',
    'FamilyTotal',
    'FamilyAccum',
    'Member',
    'MemberAccum',
    'Single',
    'SingleAccum',
  ];
  displayedColumnsCap: string[] = [
    'Phase',
    'CapType',
    'Frequency',
    'FamilyAccum',
    'Member',
    'MemberAccum',
    'Single',
    'SingleAccum',
  ];
  displayedColumnsMoop: string[] = [
    // 'DrugCategory',
    // 'Type',
    'Phase',
    'CapType',
    'Frequency',
    'Family',
    'FamilyAccum',
    'Member',
    'MemberAccum',
    'Single',
    'SingleAccum',
  ];
  displayedColumnsFill: string[] = [
    'Phase',
    'CapType',
    'Frequency',
    'Family',
    'FamilyAccum',
    'Member',
    'MemberAccum',
    'Single',
    'SingleAccum',
  ];
  displayedColumnsIpp: string[] = [
    'Phase',
    'CapType',
    'Frequency',
    'Family',
    'FamilyAccum',
    'Member',
    'MemberAccum',
    'Single',
    'SingleAccum',
  ];
  displayedColumnsMpp: string[] = [
    'Phase',
    'CapType',
    'Frequency',
    'Family',
    'FamilyAccum',
    'Member',
    'MemberAccum',
    'Single',
    'SingleAccum',
  ];
  displayedColumnsOpp: string[] = [
    'Phase',
    'CapType',
    'Frequency',
    'Family',
    'FamilyAccum',
    'Member',
    'MemberAccum',
    'Single',
    'SingleAccum',
  ];
  dataSourceDeductible: CapDeductibleAccumulation[] = [];
  dataSourceCap: CapDeductibleAccumulation[] = [];
  dataSourceMoop: CapDeductibleAccumulation[] = [];
  dataSourceFill: CapDeductibleAccumulation[] = [];
  dataSourceIpp: CapDeductibleAccumulation[] = [];
  dataSourceMpp: CapDeductibleAccumulation[] = [];
  dataSourceOpp: CapDeductibleAccumulation[] = [];

  capDeductibleMoop$!: Observable<CapDeductiblesResponse>;

  public selectedMemberDetails$: Observable<Partial<MemberDetails>>;
  clientId: string = '';
  cardId: string = '';
  public displayCoverageType = AppConstants.DISPLAY_COVERAGE_TYPE;
  personCode: string = '';
  public relationShip = AppConstants.RELATIONSHIP_VALUES;

  public navigateBackToMemberSearch(): void {
    this.router.navigateByUrl('/management/online-eligibility');
  }

  constructor(
    private router: Router,
    private readonly appInsightsService: AppInsightsService,
    private activatedRoute: ActivatedRoute,
    private eligibilityService: EligibilityService,
    private loader: LoaderService,
    private sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    this.appInsightsService.trackPageView(
      AppCenterAnalytics.CAP_DEDUCTIBLE_MOOP
    );
    this.getSelectedMemberDetails();
    // this.getCapDeductibleMoopData();
  }

  getCapDeductibleMoopData() {
    let reqBody: CapDeductibleRequestInterface = {
      clientId: this.clientId,
      cardId: this.cardId,
      personCode: this.personCode,
    };
    // console.log('Request body: ', reqBody);
    this.capDeductibleMoop$ = defer(() => {
      this.loader.showLoader();
      return this.eligibilityService.getCapDeductibleMoopDetails(reqBody).pipe(
        tap(
          (res) => {
            // console.log('Response on CapDeductible:', res);
            this.dataSourceDeductible = res.capDeductibleAccumulation.filter(
              (capDeductible) => capDeductible.type === 'Standard Deductible'
            );
            // console.log('Data source deductible:', this.dataSourceDeductible);
            this.dataSourceMoop = res.capDeductibleAccumulation.filter(
              (capDeductible) => capDeductible.type === 'Max Out Of Pocket'
            );
            this.dataSourceFill = res.capDeductibleAccumulation.filter(
              (capDeductible) => capDeductible.type === 'Fill'
            );
            this.dataSourceIpp = res.capDeductibleAccumulation.filter(
              (capDeductible) => capDeductible.type === 'Initial Plan Pay'
            );
            this.dataSourceMpp = res.capDeductibleAccumulation.filter(
              (capDeductible) => capDeductible.type === 'Max Paid By Plan'
            );
            this.dataSourceOpp = res.capDeductibleAccumulation.filter(
              (capDeductible) => capDeductible.type === 'Out Of Pocket Phase'
            );
            // console.log('Fill Data source:', this.dataSourceFill);
            // console.log('Data source moop:', this.dataSourceMoop);
            // this.dataSourceCap = res.capDeductibleAccumulation.filter(
            //   (capDeductible) =>
            //     capDeductible.type !== 'Max Out Of Pocket' &&
            //     capDeductible.type !== 'Standard Deductible'
            // );
            // console.log('Data source cap:', this.dataSourceCap);
            this.loader.hideLoader();
          },
          catchError((err) => {
            // console.log(err);
            this.loader.hideLoader();
            return of({ groupName: '', capDeductibleAccumulation: [] });
          })
        )
      );
    });
  }

  get isLevelD(): boolean {
    return this.sharedService.isLevelD();
  }

  getSelectedMemberDetails() {
    this.loader.showLoader();
    this.selectedMemberDetails$ = this.activatedRoute.queryParams.pipe(
      switchMap((res) => {
        return this.eligibilityService.getMemberDetailsById(res).pipe(
          map((res) => res[0]),
          tap((resp) => {
            // console.log('Res:', resp);
            this.appInsightsService.trackPageView(AppCenterAnalytics.CAP_DEDUCTIBLE_MOOP_API_SUCCESS, { Response: res });
            this.clientId = resp.clientId;
            this.cardId = resp.cardId;
            this.personCode = resp.personCode;
            this.loader.hideLoader();
            this.getCapDeductibleMoopData();
          }),
          catchError((err) => {
            this.appInsightsService.trackPageView(AppCenterAnalytics.CAP_DEDUCTIBLE_MOOP_API_ERROR, { Response: err });
            this.loader.hideLoader();
            return of([]);
          })
        );
      })
    );
  }
}
