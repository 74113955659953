import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Observable, Subscription, defer, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { BenefitManagementService } from 'src/app/core/services/benefit-management/benefit-management.service';
import { PreferredMedicationService } from 'src/app/core/services/preferred-medication/preferred-medication.service';
import { Clients } from 'src/app/interfaces/clients.interface';
import { PreferredMedicationFile } from 'src/app/interfaces/preferred-medication-file.interface';
import { PreferredMedicationRequestInterface } from 'src/app/interfaces/preferred-medication.interface';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { LoaderService } from '../services/loader/loader.service';
import { SharedService } from '../../services/shared/shared.service';
import { PreferredMedicationFileURL } from '../../interfaces/preferred-medication-list-response.interface';
import { EligibilityService } from '../../core/services/eligibility/eligibility.service';
import { ErrorManagerService, ErrorType } from '../../core/services/error';

@Component({
  selector: 'app-preferred-medication-list',
  templateUrl: './preferred-medication-list.component.html',
  styleUrls: ['./preferred-medication-list.component.scss'],
})
export class PreferredMedicationListComponent implements OnInit, OnDestroy {
  public listOfClients$: Observable<Array<Clients>>;
  public preferredMedicationList$: Observable<PreferredMedicationFile[]>;
  public clientFormControl = new FormControl('', [Validators.required]);
  public panelOpenState = false;
  subscription: Subscription;
  noData: boolean = false;
  private clientList: Clients[] = [];
  private client2Sub: Subscription = new Subscription();
  constructor(
    private benefitService: BenefitManagementService,
    private readonly appInsightService: AppInsightsService,
    private pmService: PreferredMedicationService,
    private loader: LoaderService,
    private sharedService: SharedService,
    private readonly eligibilityService: EligibilityService,
    private errorManager: ErrorManagerService
  ) { }

  ngOnInit(): void {
    this.appInsightService.trackPageView(
      AppCenterAnalytics.PREFERRED_MEDICATION_LIST
    );
    this.listOfClients$ = this.benefitService.getClientsListByAccess().pipe(tap(res => {
      this.clientList = res;
    }));
    this.subscription = this.eligibilityService.selectedClient3$
      .pipe(
        filter((client) => !!client),
        distinctUntilChanged()
      )
      .subscribe((client) => {
        // console.log('Client in subject preferred med list:', client);
        // let a = { value: client.clientId };
        // if (a.value) {
        //   this.clientFormControl.setValue(a.value);
        // }
        this.clientFormControl.setValue(client.clientId);
        this.onClientSelection(client);
      });
    this.client2Sub = this.eligibilityService.selectedClient2$.pipe(
      filter((client) => !!client),
      distinctUntilChanged()
    )
      .subscribe((client) => {
        // console.log('Client in subject preferred med list:', client);
        // let a = { value: client.clientId };
        // if (a.value) {
        //   this.clientFormControl.setValue(a.value);
        // }
        this.clientFormControl.setValue(client.clientId);
        this.onClientSelection(client);
      });
  }

  public onClientSelection(event: any) {
    // console.log('client selection preferred list', event);
    // this.eligibilityService.selectedClientSubject['_value'] = event;

    // localStorage.setItem('clientSelected', JSON.stringify(event));
    const reqBody: PreferredMedicationRequestInterface = {
      clientId: event.clientId,
    };
    this.preferredMedicationList$ = defer(() => {
      this.loader.showLoader();
      return this.pmService.getPreferredMedicationList(reqBody).pipe(
        map((res) => {
          res.files.map(
            (i) =>
            (i.fileURL = this.sharedService
              .getPreferredMedicationURL(i.url)
              .pipe(map((res) => res.documentUrl)))
          );
          return res.files;
        }),
        tap((res) => {
          this.appInsightService.trackPageView(AppCenterAnalytics.GET_PREFERRED_MEDICATION_LIST_API_SUCCESS ,{Response:res});
          // this.eligibilityService.setSelectedClient2(event);

          this.loader.hideLoader();
          // console.log('Response on preferredMedicationList:', res);
          if (res.length > 0) this.noData = false;
          else if (res.length === 0) this.noData = true;
        }),
        catchError((err) => {
          this.appInsightService.trackPageView(AppCenterAnalytics.GET_PREFERRED_MEDICATION_LIST_API_ERROR ,{Response: err});
          this.loader.hideLoader();
          this.noData = true;
          // console.log('Error on get preferred medication list:', err);
          return of([]);
        })
      );
    });
  }

  manualChangeClient(client: Clients) {
    // localStorage.setItem('clientSelected', JSON.stringify(client));
    this.sharedService.updateAccessLevel(client.olA_Access_Type);
    this.eligibilityService.updateIsMigratedValue(client.migrationFlag && client.migrationFlag === 'Y');
    this.eligibilityService.updateIsGroupLevelAccessValue(client?.groupLevelFlag);
    this.eligibilityService.setSelectedClient2(client);
    this.eligibilityService.setSelectedClient3(client);
    this.eligibilityService.queryString.next('');
  }

  public getFileUrl(filePath: string): Observable<PreferredMedicationFileURL> {
    return this.sharedService.getPreferredMedicationURL(filePath);
  }

  public onSelectionChange(event) {
    const selectedClient = this.clientList.filter((c) => c.clientId === event.value)[0];
    this.appInsightService.trackPageView(AppCenterAnalytics.GET_SELECTED_CLIENT_ID_FOR_PREFERRED_MEDICATION ,{data: event.value});
    this.onClientSelection(selectedClient);
    this.manualChangeClient(selectedClient);
  }
  openPdf(url: string) {
    const docURL = new URL(url);
    const pdfUrl = docURL.pathname + docURL.search;
    this.loader.showLoader();
    this.eligibilityService.preferredMedicationListById(pdfUrl).subscribe(
      (res: any) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL, '_blank');
        this.loader.hideLoader();
      },
      (error) => {
        this.errorManager.throwError({
          type: ErrorType.ERROR_GENERIC,
          message: error,
        });
        this.loader.hideLoader();
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.client2Sub) this.client2Sub.unsubscribe();
  }
}
