<div *ngIf="this.lat" class="custom-block-width">
  <!-- <div class="map-header">
    <h2>Acyclovir Capsules 200mg</h2>
    <p class="map-header-tagline">60 | 30 Days</p>
  </div> -->
  <div class="custom-dialog-width">
    <div class="map-info">
      <!-- <div class="mat-row" fxLayout="row">
        <div class="mat-4">
          <label>Plan Cost </label>
          <p class="value">$20.00</p>
        </div>
        <div class="mat-4">
          <label>Member Cost</label>
          <p class="value">$80.00</p>
        </div>
        <div class="mat-4">
          <label>Total Cost</label>
          <p class="value">$100.00</p>
        </div>
      </div> -->
      <div class="mat-row" fxLayout="row">
        <div class="mat-12">
          <h3>Pharmacy Details</h3>
        </div>
      </div>
      <div class="mat-row" fxLayout="row">
        <div class="mat-6">
          <label>Pharmacy Name </label>
          <p class="value">{{ this.pharmacy.name }}</p>
        </div>
        <div class="mat-6">
          <label>Pharmacy Address</label>
          <p class="value">
            {{ this.pharmacy.address.addressLine1 }},
            {{
              this.pharmacy.address.addressLine2
                ? this.pharmacy.address.addressLine2 + ","
                : ""
            }}
            {{ this.pharmacy.address.city }}, {{ this.pharmacy.address.state }},
            {{ this.pharmacy.address.zipCode }}
          </p>
        </div>
      </div>
      <div class="mat-row" fxLayout="row">
        <div class="mat-6">
          <label>NPI </label>
          <p class="value">{{ this.pharmacy.npi }}</p>
          <label>Phone Number </label>
          <p class="value">
            {{
              pharmacy.phoneNumber
                ? formatPhoneNumber(pharmacy.phoneNumber)
                : "-"
            }}
          </p>
          <label>NABP </label>
          <p class="value">{{ this.pharmacy.nabp }}</p>
          <label>Network Pharmacy </label>
          <p class="value">{{ this.pharmacy.inNetwork ? "YES" : "NO" }}</p>
          <label>Distance (in Miles)</label>
          <p class="value">
            {{ this.pharmacy.distance ? this.pharmacy.distance : "-" }}
          </p>
        </div>
        <div class="mat-6">
          <label>Hours of Operation</label>

          <p *ngIf="pharmacy.hours.operating24Hours">Operating 24 hours</p>
          <ng-container *ngIf="!pharmacy.hours.operating24Hours">
            <p
              class="capitalize mb-0 title-12 week-block"
              [hidden]="!getDay(day)"
              *ngFor="let day of days"
            >
              <span class="day-time">{{ day | titlecase }}</span>
              <span>{{ getDay(day) }}</span>
            </p>
          </ng-container>
          <!-- <div class="value">
            <p class="day-time"><span>Monday</span> 9 AM - 7 PM</p>
            <p class="day-time"><span>Tuesday</span> 9 AM - 7 PM</p>
            <p class="day-time"><span>Wednesday</span> 9 AM - 7 PM</p>
            <p class="day-time"><span>Thursday</span> 9 AM - 7 PM</p>
            <p class="day-time"><span>Friday</span> 9 AM - 7 PM</p>
            <p class="day-time"><span>Saturday </span> 9 AM - 5 PM</p>
            <p class="day-time"><span>Sunday </span> 9 AM - 5 PM</p>
          </div> -->
          <div class="mar-t-1">
            <label>Pharmacy Services </label>
            <p class="value">{{ pharmacy.services.join(", ") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="mapouter">
      <div
        (click)="close()"
        class="pad-b-2"
        fxLayout="row"
        fxLayoutAlign="end center"
      >
        <mat-icon class="close-icon" matListIcon>close</mat-icon>
      </div>
      <agm-map
        [zoom]="14"
        [latitude]="lat"
        [longitude]="lng"
        [disableDefaultUI]="true"
      >
        <agm-marker
          [iconUrl]="
            pharmacy.inNetwork
              ? 'assets/images/pin_red.png'
              : 'assets/images/pin_blue/pin_blue.png'
          "
          (markerClick)="openMap()"
          [latitude]="lat"
          [longitude]="lng"
        >
        </agm-marker>
      </agm-map>
    </div>
  </div>
</div>
