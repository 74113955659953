<div class="page-wrapper">
    <a class="back-arrow-inner" style="cursor: pointer" (click)="navigateBackToMemberSearch()"><mat-icon
            matListIcon>arrow_back</mat-icon> Member Search</a>
    <!-- Page Header -->
    <div class="page-header">
        <h2>Clinical Reviews</h2>
    </div>
    <ng-container *ngIf="selectedMemberDetails$ | async as selectedMemberDetails">
        <!-- Member Detail card -->
        <div class="detail-card">
            <h4>Member</h4>
            <div class="mat-row">
                <div class="mat-2">
                    <label class="card-label">Name</label>
                    <p>
                        {{ selectedMemberDetails.firstName }}
                        {{ selectedMemberDetails.lastName }}
                    </p>
                </div>
                <div class="mat-2">
                    <label class="card-label">Person Code</label>
                    <p>{{ selectedMemberDetails.personCode }}</p>
                </div>
                <div class="mat-2">
                    <label class="card-label">DOB</label>
                    <p>{{ selectedMemberDetails.DOB | timeStampToDate }}</p>
                </div>
                <div *ngIf="isInternalUser$ | async" class="mat-2">
                    <label class="card-label">EmpiRx Member ID</label>
                    <p>{{ selectedMemberDetails.empirxMemberId }}</p>
                </div>
                <div class="mat-2">
                    <label class="card-label">Card/Alt ID</label>
                    <p>{{ selectedMemberDetails.cardId }}</p>
                </div>
                <div class="mat-2">
                    <label class="card-label">Coverage Type</label>
                    <p>
                        {{
                        displayCoverageType[selectedMemberDetails?.coverage?.coverageType]
                        }}
                    </p>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="filter-block mb-1" *ngIf="showTable">
            <div class="mat-10 mt-4">
                <h4>{{totalLength}} Results Found</h4>
            </div>
            <div class="search-input-block">
                <img src="../../../../assets/images/search-icon.svg" alt="" />
                <input type="text" class="form-control" (keyup)="search($event)" placeholder="Search" #input />
            </div>
        </div>
        <div class="custom-box no-padding hidden custom-height prescriptions-table" *ngIf="showTable">

            <table mat-table [dataSource]="dataSource" matSort class="clinical-review-table">
                <!-- Inquiry Id Column -->
                <ng-container matColumnDef="inquiryId">
                    <th class="header-center" scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Inquiry Id
                    </th>
                    <td mat-cell class="center" *matCellDef="let row">{{ row.inquiryId }}</td>
                </ng-container>

                <!-- drugName Column -->
                <ng-container matColumnDef="drugName">
                    <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Drug Name
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.drugName }}</td>
                </ng-container>

                <!-- memberName Column -->
                <ng-container matColumnDef="memberName">
                    <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Member Name
                    </th>
                    <td mat-cell *matCellDef="let row">
                        {{ row.memberFirstName + " " + row.memberLastName }}
                    </td>
                </ng-container>

                <!-- createDate Column -->
                <ng-container matColumnDef="createDate">
                    <th class="header-center" scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Created Date
                    </th>
                    <td class="center" mat-cell *matCellDef="let row">
                        {{ row.createDate | timeStampToDate }}
                    </td>
                </ng-container>

                <!-- requestedBy Column -->
                <ng-container matColumnDef="requestedBy">
                    <th class="header-center" scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Requested By
                    </th>
                    <td class="center" mat-cell *matCellDef="let row">
                        {{ row.requestedBy }}
                    </td>
                </ng-container>

                <!-- status Column -->
                <ng-container matColumnDef="status">
                    <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Status
                    </th>
                    <td mat-cell *matCellDef="let row">
                        {{ row.status }}
                    </td>
                </ng-container>

                <!-- statusDescription Column -->
                <ng-container matColumnDef="statusDescription">
                    <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Status Description
                    </th>
                    <td mat-cell *matCellDef="let row">
                        {{
                        row.statusDescription
                        }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="documentUrl">
                    <th class="center" scope="col" mat-header-cell *matHeaderCellDef>
                        Clinical Reviews
                    </th>
                    <td mat-cell *matCellDef="let row" class="last-td-width center">
                        <span *ngIf="row?.documentUrl">
                            <p class="text-primary mb-0 center">
                                <a title="view" (click)="processFile(row?.documentUrl, row?.inquiryId, 'view')"
                                    class="mr-4 pointer">
                                    <img src="../../../../assets/images/file-view.svg" alt="" />
                                </a>
                                <!-- <span>Clinical Review {{row?.inquiryId}}</span> -->
                                <a title="download" (click)="processFile(row?.documentUrl, row?.inquiryId, 'download')"
                                    class="pointer">
                                    <img src="../../../../assets/images/file-download.svg" alt="" />
                                </a>
                                <!-- <span>Clinical Review {{row?.inquiryId}}</span> -->
                            </p>
                        </span>
                        <span *ngIf="!row?.documentUrl" class="center d-block dash">-</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr (keydown.enter)="$event.stopPropagation()" mat-row *matRowDef="let row; columns: displayedColumns">
                </tr>

                <!-- Row shown when there is no matching data. -->
                <ng-container *matNoDataRow>
                    <tr class="mat-row" *ngIf="totalLength == 0 || dataSource.data.length === 0">
                        <td class="mat-cell" colspan="10" role="status">
                            No Data found
                        </td>
                    </tr>
                </ng-container>
            </table>
            <div class="mt-2">
                <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </ng-container>
</div>