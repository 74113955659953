<div *ngIf="this.lat" class="custom-block-width">
  <div class="custom-dialog-width">
    <div class="map-info">
      <div class="mat-row" fxLayout="row">
        <div class="mat-12">
          <h3 class="drug-dialog-heading">{{ data.drugName }}</h3>
          <p class="title-11 mb-0 text-w-700">Brand</p>
          <div
            *ngIf="
              !data.drugPricing.pricings[0]?.pricing?.rejectMessage;
              else rejectMsg
            "
            class="price-breakdown"
          >
            <div class="price-item">
              <label>Plan Cost</label>
              <p class="value">
                {{
                  data.drugPricing.pricings[0]?.pricing?.planCost
                    ? "$" + data.drugPricing.pricings[0]?.pricing?.planCost
                    : "$-"
                }}
              </p>
            </div>
            <div class="price-item">
              <label>Member Cost</label>
              <p class="value">
                {{
                  data.drugPricing.pricings[0]?.pricing?.memberCost
                    ? "$" + data.drugPricing.pricings[0]?.pricing?.memberCost
                    : "$-"
                }}
              </p>
            </div>
            <div class="price-item">
              <label>Total Cost</label>
              <p class="value">
                {{
                  data.drugPricing.pricings[0]?.pricing?.totalCost
                    ? "$" + data.drugPricing.pricings[0]?.pricing?.totalCost
                    : "$-"
                }}
              </p>
            </div>
          </div>
          <ng-template #rejectMsg>
            <p class="reject-msg text-maroon d-flex">
              {{ data.drugPricing.pricings[0]?.pricing?.rejectMessage }}
            </p>
          </ng-template>
          <p class="title-11 mb-0 text-w-700">Generic</p>
          <div
            *ngIf="
              !data.drugPricing.pricings[0]?.genericAlternativePricing
                ?.rejectMessage;
              else rejectMsgGen
            "
            class="price-breakdown"
          >
            <div class="price-item">
              <label>Plan Cost</label>
              <p class="value">
                {{
                  data.drugPricing.pricings[0]?.genericAlternativePricing
                    ?.planCost
                    ? "$" +
                      data.drugPricing.pricings[0]?.genericAlternativePricing
                        ?.planCost
                    : "$-"
                }}
              </p>
            </div>
            <div class="price-item">
              <label>Member Cost</label>
              <p class="value">
                {{
                  data.drugPricing.pricings[0]?.genericAlternativePricing
                    ?.memberCost
                    ? "$" +
                      data.drugPricing.pricings[0]?.genericAlternativePricing
                        ?.memberCost
                    : "$-"
                }}
              </p>
            </div>
            <div class="price-item">
              <label>Total Cost</label>
              <p class="value">
                {{
                  data.drugPricing.pricings[0]?.genericAlternativePricing
                    ?.totalCost
                    ? "$" +
                      data.drugPricing.pricings[0]?.genericAlternativePricing
                        ?.totalCost
                    : "$-"
                }}
              </p>
            </div>
          </div>
          <ng-template #rejectMsgGen>
            <p
              class="title-11 mb-0 text-w-700"
            >
              {{
                data.drugPricing.pricings[0]?.genericAlternativePricing
                  ?.rejectMessage
              }}
            </p>
          </ng-template>
        </div>
      </div>
      <h3>Pharmacy Details</h3>
      <div class="mat-row" fxLayout="row">
        <div class="mat-6">
          <label>Pharmacy Name</label>
          <p class="value">{{ data.drugPricing.pharmacy.name }}</p>

          <label>NPI</label>
          <p class="value">{{ data.drugPricing.pharmacy.npi }}</p>

          <label>Phone Number</label>
          <p class="value">{{ data.drugPricing.pharmacy.phoneNumber }}</p>

          <label>NABP</label>
          <p class="value">{{ data.drugPricing.pharmacy.nabp }}</p>

          <label>Network Pharmacy</label>
          <p class="value">
            {{ data.drugPricing.pharmacy.inNetwork ? "Yes" : "No" }}
          </p>

          <label>Distance (in Miles)</label>
          <p class="value">
            {{
              data.drugPricing.pharmacy.distance
                ? data.drugPricing.pharmacy.distance
                : "-"
            }}
          </p>
        </div>

        <div class="mat-6">
          <label>Pharmacy Address</label>
          <p class="value">
            {{ data.drugPricing.pharmacy.address.addressLine1 }},
            {{
              data.drugPricing.pharmacy.address.addressLine2
                ? data.drugPricing.pharmacy.address.addressLine2 + ","
                : ""
            }}
            {{ data.drugPricing.pharmacy.address.city }},
            {{ data.drugPricing.pharmacy.address.state }},
            {{ data.drugPricing.pharmacy.address.zipCode }}
          </p>

          <label>Hours of Operation</label>
          <p *ngIf="data.drugPricing.pharmacy.hours.operating24Hours">
            Operating 24 hours
          </p>
          <ng-container
            *ngIf="!data.drugPricing.pharmacy.hours.operating24Hours"
          >
            <p
              class="capitalize mb-0 title-12 week-block"
              [hidden]="!getDay(day)"
              *ngFor="let day of days"
            >
              <span class="day-time">{{ day | titlecase }}&nbsp;</span>
              <span>{{ getDay(day) }}</span>
            </p>
          </ng-container>

          <label>Pharmacy Services</label>
          <p class="value">
            {{ data.drugPricing.pharmacy.services.join(", ") }}
          </p>
        </div>
      </div>
    </div>

    <div class="mapouter">
      <div
        class="pad-b-2"
        fxLayout="row"
        fxLayoutAlign="end center"
        (click)="cancel()"
      >
        <mat-icon class="close-icon" matListIcon>close</mat-icon>
      </div>
      <agm-map
        [zoom]="14"
        [latitude]="lat"
        [longitude]="lng"
        [disableDefaultUI]="true"
      >
        <agm-marker
          [iconUrl]="
            data.drugPricing.pharmacy.inNetwork
              ? 'assets/images/pin_red.png'
              : 'assets/images/pin_blue/pin_blue.png'
          "
          (markerClick)="openMap()"
          [latitude]="lat"
          [longitude]="lng"
        >
        </agm-marker>
      </agm-map>
    </div>
  </div>
</div>
