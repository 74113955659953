import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberSearchComponent } from './member-search.component';
import { AddMemberComponent } from './add-member/add-member.component';
import { MaterialModule } from '../../core/modules/material.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { SharedModule } from '../../shared/shared.module';
import { AddDependentComponent } from './add-dependent/add-dependent.component';
import { UpdateMemberComponent } from './update-member/update-member.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpdateCoverageComponent } from './update-coverage/update-coverage.component';
import { UpdateCoverageDetailsDialogComponent } from './update-coverage/update-coverage-details-dialog/update-coverage-details-dialog.component';
import { UpdateContactInfoComponent } from './update-contact-info/update-contact-info.component';
import { UpdateAddressInfoDialogComponent } from './update-contact-info/update-address-info-dialog/update-address-info-dialog.component';
import { UpdateContactInfoDialogComponent } from './update-contact-info/update-contact-info-dialog/update-contact-info-dialog.component';
import { CapDeductibleMoopComponent } from './cap-deductible-moop/cap-deductible-moop.component';
import { AddMemberResponseDialogComponent } from './add-member/add-member-response-dialog/add-member-response-dialog.component';
import { PharmacySearchComponent } from './pharmacy-search/pharmacy-search.component';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { environment } from 'src/environments/environment';
import { PharmacyDetailsComponent } from './pharmacy-details/pharmacy-details.component';
import { ClaimSearchDetailComponent } from './claim-search/claim-search-detail/claim-search-detail.component';
import { ClaimSearchCompareComponent } from './claim-search/claim-search-compare/claim-search-compare.component';
import { DrugPricingComponent } from './drug-pricing/drug-pricing.component';
import { PrintIdCardComponent } from './print-id-card/print-id-card.component';
import { DrugDetailsComponent } from './drug-details/drug-details.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RequestClaimsDataDialogComponent } from './claim-search/request-claims-data-dialog/request-claims-data-dialog.component';
import { MailOrdersComponent } from './mail-orders/mail-orders.component';
import { PrescriptionsComponent } from './prescriptions/prescriptions.component';
import { ClinicalReviewsComponent } from './clinical-reviews/clinical-reviews.component';
import { MailOrderDetailsDialogComponent } from './mail-orders/mail-order-details-dialog/mail-order-details-dialog.component';
import { PrescriptionDetailsDialogComponent } from './prescriptions/prescription-details-dialog/prescription-details-dialog.component';

@NgModule({
  declarations: [
    MemberSearchComponent,
    AddMemberComponent,
    AddDependentComponent,
    UpdateMemberComponent,
    UpdateCoverageComponent,
    UpdateCoverageDetailsDialogComponent,
    UpdateContactInfoComponent,
    UpdateAddressInfoDialogComponent,
    UpdateContactInfoDialogComponent,
    CapDeductibleMoopComponent,
    AddMemberResponseDialogComponent,
    PharmacySearchComponent,
    PharmacyDetailsComponent,
    ClaimSearchDetailComponent,
    ClaimSearchCompareComponent,
    DrugPricingComponent,
    PrintIdCardComponent,
    DrugDetailsComponent,
    RequestClaimsDataDialogComponent,
    MailOrdersComponent,
    PrescriptionsComponent,
    ClinicalReviewsComponent,
    MailOrderDetailsDialogComponent,
    PrescriptionDetailsDialogComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatAutocompleteModule,
    MatSortModule,
    MatTableModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
    }),
  ],
  providers: [GoogleMapsAPIWrapper],
})
export class OnlineEligibilityModule { }
