<div class="page-wrapper">
  <a
    class="back-arrow-inner"
    style="cursor: pointer"
    (click)="navigateBackToMemberSearch()"
    ><mat-icon matListIcon>arrow_back</mat-icon> Member Search</a
  >
  <div class="page-header">
    <h2>Update Contact Info</h2>
  </div>

  <ng-container *ngIf="selectedMemberDetails$ | async as selectedMemberDetails">
    <div class="detail-card">
      <h4>Member</h4>
      <div class="mat-row">
        <div class="mat-2">
          <label class="card-label">Name</label>
          <p>
            {{ selectedMemberDetails.firstName }}
            {{ selectedMemberDetails.lastName }}
          </p>
        </div>
        <div class="mat-2">
          <label class="card-label">Person Code</label>
          <p>{{ selectedMemberDetails.personCode }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">DOB</label>
          <p>{{ selectedMemberDetails.DOB | timeStampToDate }}</p>
        </div>
        <div *ngIf="isInternalUser$ | async" class="mat-2">
          <label class="card-label">EmpiRx Member ID</label>
          <p>{{ selectedMemberDetails.empirxMemberId }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Card/Alt ID</label>
          <p>{{ selectedMemberDetails.cardId }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Coverage Type</label>
          <p>
            {{
              displayCoverageType[selectedMemberDetails?.coverage?.coverageType]
            }}
          </p>
        </div>
      </div>
    </div>

    <!-- Search by -->
    <div class="search-by-container">
      <h4>Search By</h4>
      <div class="custom-tab">
        <mat-tab-group
          [(selectedIndex)]="selectedIndex"
          animationDuration="0ms"
        >
          <mat-tab label="Address"> </mat-tab>
          <mat-tab label="Contact"> </mat-tab>
        </mat-tab-group>

        <!--Form-->
        <form [formGroup]="">
          <div class="tab-action-group">
            <ng-container *ngIf="selectedIndex === 0">
              <div class="form-wrapper">
                <div class="mat-row">
                  <div class="mat-12">
                    <h4>Address</h4>
                  </div>
                </div>
                <div class="mat-row">
                  <div class="mat-6">
                    <div class="address-card">
                      <p>
                        {{
                          selectedMemberDetails.addressLine1 +
                            ", " +
                            (selectedMemberDetails.addressLine2
                              ? selectedMemberDetails.addressLine2 + ", "
                              : "") +
                            selectedMemberDetails.city +
                            ", " +
                            selectedMemberDetails.state +
                            ", " +
                            selectedMemberDetails.zipCode
                        }}
                      </p>
                      <mat-icon (click)="editAddress()">edit</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="selectedIndex === 1">
              <div class="form-wrapper">
                <div class="mat-row">
                  <div class="mat-6">
                    <h4>Contact</h4>
                  </div>
                  <div class="mat-6">
                    <div fxLayoutAlign="end end">
                      <button
                        mat-stroked-button
                        rounded
                        color="accent"
                        *ngIf="checkIfContactCanBeAdded()"
                        (click)="editContact('contact', {}, false)"
                        class="no-border-btn"
                      >
                        Add a New Contact
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mat-row">
                  <ng-container *ngFor="let phone of memberDetails?.phones">
                    <div
                      class="mat-6 pad-r-2"
                      *ngIf="
                        phone?.phoneNumber && phone?.phoneNumber !== 'null'
                      "
                    >
                      <div class="address-card">
                        <label class="card-label">{{
                          phoneTypes[phone?.type]
                        }}</label>
                        <p>
                          {{
                            phone?.phoneNumber
                              ? formatPhoneNumber(phone?.phoneNumber)
                              : "-"
                          }}
                        </p>
                        <mat-icon (click)="editContact('contact', phone)"
                          >edit</mat-icon
                        >
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="mat-row">
                  <div class="mat-6">
                    <h4>Email</h4>
                  </div>
                  <div class="mat-6">
                    <div fxLayoutAlign="end end">
                      <button
                        mat-stroked-button
                        rounded
                        color="accent"
                        *ngIf="
                          !memberDetails.email || memberDetails.email === 'null'
                        "
                        (click)="editContact('email', {})"
                        class="no-border-btn"
                      >
                        Add Email
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="mat-row pad-b-1"
                  *ngIf="memberDetails.email && memberDetails.email !== 'null'"
                >
                  <div class="mat-6 pad-r-2">
                    <div class="address-card">
                      <p>{{ memberDetails.email }}</p>
                      <mat-icon (click)="editContact('email', {})"
                        >edit</mat-icon
                      >
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </form>
        <mat-divider></mat-divider>
      </div>
    </div>
    <div class="mat-row dialog-btn-row">
      <div class="mat-12" fxLayoutAlign="end end">
        <button
          mat-button
          class="secondary-btn"
          (click)="navigateBackToMemberSearch()"
        >
          Cancel
        </button>
      </div>
    </div>
  </ng-container>
</div>
