/**
 * @file error-handler.service.ts
 * @description handles error by showing Error Dialog
 */
import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import { ErrorManagerService, ErrorObject } from '../../core/services/error';
import { ErrorComponent } from '../../core/components/error/error.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private static readonly className = 'ErrorHandlerService';

  /**
   * @param  ErrorManagerService error manager service
   * @param  MatDialog Material Dialog whish shows error
   */
  constructor(
    private readonly errorManager: ErrorManagerService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.errorManager.getErrorObservable().subscribe((error: ErrorObject) => {
      this.handleError(error);
    });
  }
  /**
   * @param  ErrorObject error object contains title and message
   * @returns void
   */
  private handleError(error: ErrorObject): void {
    const dialogRef: MatDialogRef<ErrorComponent> = this.dialog.open(
      ErrorComponent,
      {
        autoFocus: false,
        minWidth: 400,
        data: {
          title: error.title,
          message: error.message,
        },
      }
    );
    dialogRef.afterClosed().subscribe(() => {
      if (error.component === 'AccessLevelGuard') {
        this.router.navigateByUrl('/app')
      }
      // console.log('closed');
    });
  }
}
