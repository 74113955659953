<div class="page-wrapper">
  <div class="mar-b-1">
    <a class="back-arrow-inner" style="cursor: pointer" (click)="navigateBackToClaimSearch()">
      <mat-icon matListIcon>arrow_back</mat-icon>Member Claim Search</a>
  </div>
  <!-- Member Coverage | Dependant -->
  <div class="see-more-accordian mar-b-1" *ngIf="claimSearchDetails$ | async as claimSearchDetails">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Client Information </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Client ID</label>
            <p>{{ claimDetails.clientId }}</p>
          </div>
          <div class="mat-6">
            <label class="card-label">Group Id </label>
            <p>{{ claimDetails.groupId }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Client Name</label>
            <p>{{ claimDetails.clientName }}</p>
          </div>
          <div class="mat-6">
            <label class="card-label">Group Name </label>
            <p>{{ claimDetails.groupName }}</p>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="dependents-accordion">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Member Information </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Card/Alt ID</label>
            <p>{{ claimDetails.cardId }}</p>
          </div>
          <div class="mat-6">
            <label class="card-label">Date of birth </label>
            <p>
              {{
              claimDetails.dob
              ? (claimDetails.dob | timeStampToDate)
              : claimDetails.dob
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label"> Name</label>
            <p>{{ claimDetails.name }}</p>
          </div>
          <div class="mat-6">
            <label class="card-label">Person Code</label>
            <p>{{ claimDetails.personCode }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label"> Gender</label>
            <p>{{ claimDetails.gender === "F" ? "Female" : "Male" }}</p>
          </div>
          <div class="mat-6">
            <label class="card-label">Relationship Code</label>
            <p>{{ claimDetails.relationshipCode }}</p>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Claim Information </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Claim ID</label>
            <p>{{ claimDetails.claimId }}</p>
          </div>
          <div class="mat-6">
            <label class="card-label">Auth Number</label>
            <p>{{ claimDetails.authNum? claimDetails.authNum: '-' }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Process Date </label>
            <p>
              {{
              claimDetails.processDate
              ? (claimDetails.processDate | date : "MM/dd/yyyy, hh:mm:ss a")
              : claimDetails.processDate
              }}
            </p>
          </div>
          <div class="mat-6">
            <label class="card-label">RX Number</label>
            <p>{{ claimDetails.rxNumber }}</p>
          </div>

        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Fill Date</label>
            <p>
              {{
              claimDetails.rxFillDate
              ? (claimDetails.rxFillDate | timeStampToDate)
              : claimDetails.rxFillDate
              }}
            </p>
          </div>
          <div class="mat-6">
            <label class="card-label">Fill Number</label>
            <p>{{ claimDetails.fillNumber }}</p>
          </div>

        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Written Date</label>
            <p>
              {{
              claimDetails.writtenDate
              ? (claimDetails.writtenDate | timeStampToDate)
              : claimDetails.writtenDate
              }}
            </p>
          </div>
          <div class="mat-6">
            <label class="card-label">Quantity Prescribed</label>
            <p>{{ claimDetails.quantityPrescribed }}</p>
          </div>

        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Compound</label>
            <p>{{ claimDetails.compound ? claimDetails.compound : "-" }}</p>
          </div>
          <div class="mat-6">
            <label class="card-label">Quantity Dispensed</label>
            <p>
              {{
              claimDetails.quantityDispensed
              ? claimDetails.quantityDispensed
              : "-"
              }}
            </p>
          </div>

        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">DAW</label>
            <p>{{ claimDetails.daw ? claimDetails.daw : "-" }}</p>
          </div>
          <div class="mat-6">
            <label class="card-label">Days Supply</label>
            <p>{{ claimDetails.daysSupply ? claimDetails.daysSupply : "-" }}</p>
          </div>

        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Prescriber</label>
            <p>
              {{ claimDetails.prescriber? claimDetails.prescriber: '-' }}
            </p>
          </div>
          <div class="mat-6">
            <label class="card-label">Pharmacy</label>
            <p>{{ claimDetails.pharmacy }}</p>
          </div>

        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Prescriber NPI</label>
            <p>{{ claimDetails.prescriberNPI? claimDetails.prescriberNPI: '-' }}</p>
          </div>
          <div class="mat-6">
            <label class="card-label">Pharmacy NPI</label>
            <p>{{ claimDetails.pharmacyNPI? claimDetails.pharmacyNPI: '-' }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Pharmacy Address</label>
            <p>
              {{ claimDetails.pharmacyAddress }}
            </p>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="dependents-accordion">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Drug Information </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Medication</label>
            <p>{{ claimDetails.medication }}</p>
          </div>
          <div class="mat-6">
            <label class="card-label">NDC</label>
            <p>{{ claimDetails.ndc }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Brand/Generic</label>
            <p>{{ claimDetails.brandGeneric === "B" ? "Brand" : "Generic" }}</p>
          </div>
          <div class="mat-6">
            <label class="card-label">Specialty</label>
            <p>{{ getSpecialtyValue(claimDetails) }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Route of Admin</label>
            <p>{{ claimDetails.routeOfAdmin }}</p>
          </div>
          <div class="mat-6">
            <label class="card-label">Formulary Tier</label>
            <p>
              {{
              claimDetails.formularyTier ? claimDetails.formularyTier : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Mutisource code</label>
            <ng-container [ngSwitch]="claimDetails.multiSourceCode">
              <p *ngSwitchCase="'M'">
                Single-source, co-licensed product, Brand
              </p>
              <p *ngSwitchCase="'O'">Multi-source, originator product, Brand</p>
              <p *ngSwitchCase="'N'">Single-source product, Brand</p>
              <p *ngSwitchCase="'Y'">Multi-source product, Generic</p>
            </ng-container>
          </div>
          <div class="mat-6">
            <label class="card-label">Maintenance</label>
            <ng-container [ngSwitch]="claimDetails.maintenance">
              <p *ngSwitchCase="'0'">No</p>
              <p *ngSwitchCase="'1'">No</p>
              <p *ngSwitchCase="'2'">Yes</p>
            </ng-container>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Prior Auth List</label>
            <p>
              {{
              claimDetails.priorAuthList ? claimDetails.priorAuthList : "-"
              }}
            </p>
          </div>
          <!-- <div class="mat-6">
            <label class="card-label">Indications</label>
            <p>{{ claimDetails.indication ? claimDetails.indication : "-" }}</p>
          </div> -->
        </div>
        <div fxLayout="row">
          <!-- <div class="mat-6">
            <label class="card-label">Step Therapy Protocol</label>
            <p>
              {{
                claimDetails.steptherapyProtocol
                  ? claimDetails.steptherapyProtocol
                  : "-"
              }}
            </p>
          </div> -->
          <div class="mat-6">
            <label class="card-label">Primary Drug Category </label>
            <p>
              {{
              claimDetails.primaryDrugCategory
              ? claimDetails.primaryDrugCategory
              : "-"
              }}
            </p>
          </div>
        </div>
        <!-- <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Quantity Limit List</label>
            <p>
              {{
                claimDetails.quantityLimitList
                  ? claimDetails.quantityLimitList
                  : "-"
              }}
            </p>
          </div>
        </div> -->
        <!-- <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Equivalent Medications</label>
            <p>
              {{
                claimDetails.equivalentMedication
                  ? claimDetails.equivalentMedication
                  : "-"
              }}
            </p>
          </div>
        </div> -->
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Status Information </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Status</label>
            <p>{{ claimDetails.status }}</p>
            <!-- <ng-container [ngSwitch]="claimDetails.status">
              <p *ngSwitchCase="'R'">Rejected</p>
              <p *ngSwitchCase="'AR'">Accepted Reversal</p>
              <p *ngSwitchCase="'AP'">Accepted Paid</p>
              <p *ngSwitchCase="'AA'">All Accepted Claims</p>
            </ng-container> -->
          </div>
          <div class="mat-6">
            <label class="card-label">Rejected Code</label>
            <p>{{ claimDetails.rejectCode ? claimDetails.rejectCode : "-" }}</p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Processed as Non-Primary</label>
            <p>
              {{ claimDetails.processedAsPrimary === "false" ? "Yes" : "No" }}
            </p>
          </div>
          <div class="mat-6">
            <label class="card-label">Rejected Code description</label>
            <p>
              {{
              claimDetails.rejectCodeDescription
              ? claimDetails.rejectCodeDescription
              : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Additional Message</label>
            <p>
              {{
              claimDetails.additionalMessage
              ? claimDetails.additionalMessage
              : "-"
              }}
            </p>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="dependents-accordion">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Pricing Information </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="pricing-info">
          <div>
            <label class="card-label">Plan Amount</label>
            <p>
              {{
              claimDetails.planAmount
              ? (claimDetails.planAmount | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div *ngIf="userAccessLevel !== userAccessLevels.LEVEL_C">
            <label class="card-label">Ingredient Cost </label>
            <p>
              {{
              claimDetails.ingredientCost
              ? (claimDetails.ingredientCost | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div>
            <label class="card-label">Total Member Responsibility</label>
            <p>
              {{
              claimDetails.totalMemberResponsibility
              ? (claimDetails.totalMemberResponsibility | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div *ngIf="userAccessLevel !== userAccessLevels.LEVEL_C">
            <label class="card-label">Dispensing fee</label>
            <p>
              {{
              claimDetails.dispensingFee
              ? (claimDetails.dispensingFee | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div>
            <label class="card-label">Third Party Assistance</label>
            <p>
              {{
              thirdPartyAssistance
              ? (thirdPartyAssistance | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div>
            <label class="card-label">Shipping charge </label>
            <p>
              {{
              claimDetails.shippingCharge
              ? (claimDetails.shippingCharge | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div>
            <label class="card-label">Total Member Pay</label>
            <p>
              {{
              claimDetails.totalMemberPay
              ? (claimDetails.totalMemberPay | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div>
            <label class="card-label">Sales tax </label>
            <p>
              {{
              claimDetails.salesTax
              ? (claimDetails.salesTax | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div>
            <label class="card-label">Member Co-pay</label>
            <p>
              {{
              claimDetails.memberCopay
              ? (claimDetails.memberCopay | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div>
            <label class="card-label">Incentive Amount</label>
            <p>
              {{
              claimDetails.incentiveAmount
              ? (claimDetails.incentiveAmount | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div>
            <label class="card-label">Member Deductible</label>
            <p>
              {{
              claimDetails.memberDeductible
              ? (claimDetails.memberDeductible | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div>
            <label class="card-label">Total Cost</label>
            <p>
              {{
              claimDetails.totalCost
              ? (claimDetails.totalCost | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div>
            <label class="card-label">Member out of pocket</label>
            <p>
              {{
              claimDetails.memberOutOfPocket
              ? (claimDetails.memberOutOfPocket | currency : "USD")
              : "-"
              }}
            </p>
          </div>
          <div>
            <label class="card-label">Pricing Source used</label>
            <p>
              {{
              claimDetails.pricingSourceUsed
              ? claimDetails.pricingSourceUsed
              : "-"
              }}
            </p>
          </div>
          <div *ngIf="userAccessLevel !== userAccessLevels.LEVEL_C">
            <label class="card-label">100% AWP</label>
            <p>
              {{
              claimDetails.awp ? (claimDetails.awp | currency : "USD") : "-"
              }}
            </p>
          </div>
        </div>

        <div fxLayout="row">
          <!-- <div class="mat-6">
            <label class="card-label">Ancillary Charge</label>
            <p>
              {{
                claimDetails.ancillaryCharge
                  ? (claimDetails.ancillaryCharge | currency : "USD")
                  : "-"
              }}
            </p>
          </div> -->

        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Pharmacy Information </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Reimbursement Amount</label>
            <p>
              {{
                claimDetails.pricingInformation?.pharmacyReimbursementAmount
                  | currency : "USD"
              }}
            </p>
          </div>
          <div class="mat-6">
            <label class="card-label">Ingredient Cost</label>
            <p>
              {{
                claimDetails.ingredientCost
                  ? (claimDetails.ingredientCost | currency : "USD")
                  : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Total Member Responsibility</label>
            <p>
              {{
                claimDetails.pharmacyReimbursement.totalMemberresponsibility
                  ? (claimDetails.pharmacyReimbursement
                      .totalMemberresponsibility | currency : "USD")
                  : "-"
              }}
            </p>
          </div>
          <div class="mat-6">
            <label class="card-label">Dispensing Fee</label>
            <p>
              {{
                claimDetails.pharmacyReimbursement?.dispensingfee
                  ? (claimDetails.pharmacyReimbursement?.dispensingfee
                    | currency : "USD")
                  : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Third Party Assistance</label>
            <p>
              {{
                claimDetails.pharmacyReimbursement?.thirdPartAssistance
                  ? (claimDetails.pharmacyReimbursement?.thirdPartAssistance
                    | currency : "USD")
                  : "-"
              }}
            </p>
          </div>
          <div class="mat-6">
            <label class="card-label">Shipping Charge</label>
            <p>
              {{
                claimDetails.pharmacyReimbursement?.shippingCharge
                  ? (claimDetails.pharmacyReimbursement?.shippingCharge
                    | currency : "USD")
                  : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Total Member Pay</label>
            <p>
              {{
                claimDetails.pharmacyReimbursement?.totalMemberPay
                  ? (claimDetails.pharmacyReimbursement?.totalMemberPay
                    | currency : "USD")
                  : "-"
              }}
            </p>
          </div>
          <div class="mat-6">
            <label class="card-label">Sales tax</label>
            <p>
              {{
                claimDetails.pharmacyReimbursement?.salesTax
                  ? (claimDetails.pharmacyReimbursement?.salesTax
                    | currency : "USD")
                  : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Member Co-pay</label>
            <p>
              {{
                claimDetails.pharmacyReimbursement?.memberCopay
                  ? (claimDetails.pharmacyReimbursement?.memberCopay
                    | currency : "USD")
                  : "-"
              }}
            </p>
          </div>
          <div class="mat-6">
            <label class="card-label">Incentive Amount</label>
            <p>
              {{
                claimDetails.pharmacyReimbursement?.incentiveAmount
                  ? (claimDetails.pharmacyReimbursement?.incentiveAmount
                    | currency : "USD")
                  : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Member Deductible</label>
            <p>
              {{
                claimDetails.pharmacyReimbursement?.memberDeductible
                  ? (claimDetails.pharmacyReimbursement?.memberDeductible
                    | currency : "USD")
                  : "-"
              }}
            </p>
          </div>
          <div class="mat-6">
            <label class="card-label">Toatal Cost</label>
            <p>
              {{
                claimDetails.pharmacyReimbursement?.totalCost
                  ? (claimDetails.pharmacyReimbursement?.totalCost
                    | currency : "USD")
                  : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Member out of pocket</label>
            <p>
              {{
                claimDetails.pharmacyReimbursement?.memberOutOfPocket
                  ? (claimDetails.pharmacyReimbursement?.memberOutOfPocket
                    | currency : "USD")
                  : "-"
              }}
            </p>
          </div>
          <div class="mat-6">
            <label class="card-label">Pricing Source used</label>
            <p>
              {{
                claimDetails.pharmacyReimbursement?.pricingSourceUsed
                  ? (claimDetails.pharmacyReimbursement?.pricingSourceUsed
                    | currency : "USD")
                  : "-"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="row">
          <div class="mat-6">
            <label class="card-label">Ancillary Charge</label>
            <p>
              {{
                claimDetails.pharmacyReimbursement?.ancillaryCharge
                  ? (claimDetails.pharmacyReimbursement?.ancillaryCharge
                    | currency : "USD")
                  : "-"
              }}
            </p>
          </div>
          <div class="mat-6">
            <label class="card-label">100% AWP</label>
            <p>
              {{
                claimDetails.pharmacyReimbursement?.AWP
                  ? (claimDetails.pharmacyReimbursement?.AWP | currency : "USD")
                  : "-"
              }}
            </p>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion> -->
  </div>
</div>