<div class="page-wrapper">
  <a class="back-arrow-inner" style="cursor: pointer" (click)="navigateBackToMemberSearch()"><mat-icon
      matListIcon>arrow_back</mat-icon> Member Search</a>
  <!-- Page Header -->
  <div class="page-header">
    <h2>Print ID Card</h2>
  </div>

  <ng-container *ngIf="selectedMemberDetails$ | async as selectedMemberDetails"><!-- Member Detail card -->
    <div class="detail-card mar-b-15">
      <h4>Member</h4>
      <div class="mat-row">
        <div class="mat-2">
          <label class="card-label">Name</label>
          <p>
            {{ selectedMemberDetails.firstName }}
            {{ selectedMemberDetails.lastName }}
          </p>
        </div>
        <div class="mat-2">
          <label class="card-label">Person Code</label>
          <p>{{ selectedMemberDetails.personCode }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">DOB</label>
          <p>{{ selectedMemberDetails.DOB | timeStampToDate }}</p>
        </div>
        <div *ngIf="isInternalUser$ | async" class="mat-2">
          <label class="card-label">EmpiRx Member ID</label>
          <p>{{ selectedMemberDetails.empirxMemberId }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Card/Alt ID</label>
          <p>{{ selectedMemberDetails.cardId }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Coverage Type</label>
          <p>
            {{
            displayCoverageType[selectedMemberDetails?.coverage?.coverageType]
            }}
          </p>
        </div>
      </div>
    </div>

    <h3 class="form-heading">Digital ID Card</h3>
    <div class="print-card-wrapper" *ngIf="isIdCardAvailable">
      <span>
        <img [src]="imageUrl" alt="print card" />
      </span>
    </div>
    <div class="print-card-wrapper" *ngIf="!isIdCardAvailable && selectedMemberDetails">
      <div class="slimmed-id-card">
        <img class="brand" src="../../../../assets/images/brand-logo/logo.svg" width="75" alt="EmpiRx">
        <div class="id-data">
          <div class="mb-40">
            <label>ID</label>
            <p class="id">{{ selectedMemberDetails?.cardId }}</p>
          </div>
          <div>
            <label>Name</label>
            <p class="name">{{ selectedMemberDetails?.firstName }}
              {{ selectedMemberDetails?.lastName }}</p>
          </div>
          <div class="mat-row">
            <div class="mat-5">
              <label>RXBIN</label>
              <p>024160</p>
            </div>
            <div class="mat-5">
              <label>RXPCN</label>
              <p>14032015</p>
            </div>
            <div class="mat-5">
              <label>RXGRP</label>
              <p>{{ selectedMemberDetails?.clientId }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="note-wrapper" *ngIf="!isIdCardAvailable">
    <p>
      Note: This is a temporary ID card, the permanent digital card will be generated in 24 to 48 hours.
    </p>
  </div>
  <div class="mat-row button-row" fxLayout="row">
    <div class="mat-12" fxLayoutAlign="end end">
      <button mat-button class="primary-btn" *ngIf="isIdCardAvailable" (click)="downloadImage()">
        Download ID
      </button>
      <button mat-button class="secondary-btn" *ngIf="isIdCardAvailable" (click)="openPrintWindow()">
        Print ID
      </button>
      <button mat-button class="secondary-btn" (click)="navigateBackToMemberSearch()">
        Cancel
      </button>
    </div>
  </div>
</div>