<div class="page-wrapper">
  <a class="back-arrow-inner" style="cursor: pointer" (click)="navigateBackToMemberSearch()"><mat-icon
      matListIcon>arrow_back</mat-icon> Member Search</a>
  <div class="accordian-detial-wrapper">
    <!-- Member Detail card -->
    <div class="detail-card" *ngIf="selectedMemberDetails$ | async as selectedMemberDetails">
      <div class="client-id-name">
        <h4>Member</h4>
        <span>(Client: {{selectedMemberDetails.clientId}}-{{selectedMemberDetails.client_full_name}})</span>
      </div>
      <div class="mat-row">
        <div class="mat-2">
          <label class="card-label">Name</label>
          <p>
            {{ selectedMemberDetails.firstName }}
            {{ selectedMemberDetails.lastName }}
          </p>
        </div>
        <div class="mat-2">
          <label class="card-label">Person Code</label>
          <p>{{ selectedMemberDetails.personCode }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">DOB</label>
          <p>{{ selectedMemberDetails.DOB | timeStampToDate }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Relationship</label>
          <p>{{selectedMemberDetails.relationshipCode}} - {{relationShip[selectedMemberDetails.relationshipCode] }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Card/Alt ID</label>
          <p>{{ selectedMemberDetails.cardId }}</p>
        </div>
        <div class="mat-2">
          <label class="card-label">Coverage Type</label>
          <p>
            {{
            displayCoverageType[selectedMemberDetails?.coverage?.coverageType]
            }}
          </p>
        </div>
      </div>
    </div>

    <div class="see-more-accordian mar-b-1" *ngIf="capDeductibleMoop$ | async as capDeductibleMoop">
      <mat-accordion *ngIf="dataSourceDeductible.length > 0">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Deductible </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row" class="display-table">
            <table mat-table [dataSource]="dataSourceDeductible" class="mat-elevation-z8">
              <ng-container matColumnDef="Phase">
                <th mat-header-cell *matHeaderCellDef>Phase</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.alias }}
                </td>
              </ng-container>
              <ng-container matColumnDef="Frequency">
                <th mat-header-cell *matHeaderCellDef>Frequency</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.frequency | titlecase }}
                </td>
              </ng-container>

              <ng-container matColumnDef="FamilyTotal">
                <th mat-header-cell *matHeaderCellDef>Family Total</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyLimit !== null
                  ? (element.familyLimit | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="FamilyAccum">
                <th mat-header-cell *matHeaderCellDef>Family Accum.</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyAccumulation !== null
                  ? (element.familyAccumulation | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Member">
                <th mat-header-cell *matHeaderCellDef>Member</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyMemberLimit !== null
                  ? (element.familyMemberLimit | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="MemberAccum">
                <th mat-header-cell *matHeaderCellDef>Member Accum.</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyMemberAccumulation !== null
                  ? (element.familyMemberAccumulation | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Single">
                <th mat-header-cell *matHeaderCellDef>Single</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.singleLimit !== null
                  ? (element.singleLimit | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="SingleAccum">
                <th mat-header-cell *matHeaderCellDef>Single Accum.</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.singleAccumulation !== null
                  ? (element.singleAccumulation | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <!-- Row Definitions -->
              <tr mat-header-row *matHeaderRowDef="displayedColumnsDeductible"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsDeductible"></tr>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion class="dependents-accordion" *ngIf="dataSourceFill.length > 0">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Fill </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row" class="display-table">
            <table class="mar-t-1" mat-table [dataSource]="dataSourceFill">
              <ng-container matColumnDef="Phase">
                <th mat-header-cell *matHeaderCellDef>Phase</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.alias }}
                </td>
              </ng-container>

              <ng-container matColumnDef="CapType">
                <th mat-header-cell *matHeaderCellDef>Cap Type</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.type | titlecase }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Frequency">
                <th mat-header-cell *matHeaderCellDef>Frequency</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.frequency | titlecase }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Family">
                <th mat-header-cell *matHeaderCellDef>Family</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyLimit !== null
                  ? (element.familyLimit | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="FamilyAccum">
                <th mat-header-cell *matHeaderCellDef>Family Accum.</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyAccumulation !== null
                  ? (element.familyAccumulation | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Member">
                <th mat-header-cell *matHeaderCellDef>Member</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyMemberLimit !== null
                  ? (element.familyMemberLimit | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="MemberAccum">
                <th mat-header-cell *matHeaderCellDef>Member Accum.</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyMemberAccumulation !== null
                  ? (element.familyMemberAccumulation | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>


              <ng-container matColumnDef="Single">
                <th mat-header-cell *matHeaderCellDef>Single</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.singleLimit !== null
                  ? (element.singleLimit | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="SingleAccum">
                <th mat-header-cell *matHeaderCellDef>Single Accum.</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.singleAccumulation !== null
                  ? (element.singleAccumulation | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsFill"></tr>

              <tr mat-row *matRowDef="let row; columns: displayedColumnsFill"></tr>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion class="dependents-accordion" *ngIf="dataSourceMoop.length > 0">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Max Out Of Pocket </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row" class="display-table">
            <table class="mar-t-1" mat-table [dataSource]="dataSourceMoop">
              <ng-container matColumnDef="Phase">
                <th scope="col" mat-header-cell *matHeaderCellDef>Phase</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.alias | uppercase }}
                </td>
              </ng-container>

              <ng-container matColumnDef="CapType">
                <th scope="col" mat-header-cell *matHeaderCellDef>Cap Type</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.type | titlecase }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Frequency">
                <th scope="col" mat-header-cell *matHeaderCellDef>Frequency</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.frequency | titlecase }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Family">
                <th scope="col" mat-header-cell *matHeaderCellDef>Family</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyLimit !== null
                  ? (element.familyLimit | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="FamilyAccum">
                <th scope="col" mat-header-cell *matHeaderCellDef>
                  Family Accum.
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyAccumulation !== null
                  ? (element.familyAccumulation | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Member">
                <th scope="col" mat-header-cell *matHeaderCellDef>Member</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyMemberLimit !== null
                  ? (element.familyMemberLimit | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="MemberAccum">
                <th scope="col" mat-header-cell *matHeaderCellDef>
                  Member Accum.
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyMemberAccumulation !== null
                  ? (element.familyMemberAccumulation | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Single">
                <th mat-header-cell *matHeaderCellDef>Single</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.singleLimit !== null
                  ? (element.singleLimit | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="SingleAccum">
                <th mat-header-cell *matHeaderCellDef>Single Accum.</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.singleAccumulation !== null
                  ? (element.singleAccumulation | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsMoop"></tr>

              <tr mat-row *matRowDef="let row; columns: displayedColumnsMoop"></tr>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion class="dependents-accordion" *ngIf="dataSourceIpp.length > 0">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Initial Plan Pay </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row" class="display-table">
            <table class="mar-t-1" mat-table [dataSource]="dataSourceIpp">
              <ng-container matColumnDef="Phase">
                <th scope="col" mat-header-cell *matHeaderCellDef>Phase</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.alias | uppercase }}
                </td>
              </ng-container>
              <ng-container matColumnDef="CapType">
                <th scope="col" mat-header-cell *matHeaderCellDef>Cap Type</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.type | titlecase }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Frequency">
                <th scope="col" mat-header-cell *matHeaderCellDef>Frequency</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.frequency | titlecase }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Family">
                <th scope="col" mat-header-cell *matHeaderCellDef>Family</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyLimit !== null
                  ? (element.familyLimit | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="FamilyAccum">
                <th scope="col" mat-header-cell *matHeaderCellDef>
                  Family Accum.
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyAccumulation !== null
                  ? (element.familyAccumulation | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Member">
                <th scope="col" mat-header-cell *matHeaderCellDef>Member</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyMemberLimit !== null
                  ? (element.familyMemberLimit | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="MemberAccum">
                <th scope="col" mat-header-cell *matHeaderCellDef>
                  Member Accum.
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyMemberAccumulation !== null
                  ? (element.familyMemberAccumulation | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Single">
                <th mat-header-cell *matHeaderCellDef>Single</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.singleLimit !== null
                  ? (element.singleLimit | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="SingleAccum">
                <th mat-header-cell *matHeaderCellDef>Single Accum.</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.singleAccumulation !== null
                  ? (element.singleAccumulation | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsIpp"></tr>

              <tr mat-row *matRowDef="let row; columns: displayedColumnsIpp"></tr>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion class="dependents-accordion" *ngIf="dataSourceMpp.length > 0">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Max Paid By Plan </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row" class="display-table">
            <table class="mar-t-1" mat-table [dataSource]="dataSourceMpp">
              <ng-container matColumnDef="Phase">
                <th scope="col" mat-header-cell *matHeaderCellDef>Phase</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.alias | uppercase }}
                </td>
              </ng-container>
              <ng-container matColumnDef="CapType">
                <th scope="col" mat-header-cell *matHeaderCellDef>Cap Type</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.type | titlecase }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Frequency">
                <th scope="col" mat-header-cell *matHeaderCellDef>Frequency</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.frequency | titlecase }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Family">
                <th scope="col" mat-header-cell *matHeaderCellDef>Family</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyLimit !== null
                  ? (element.familyLimit | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="FamilyAccum">
                <th scope="col" mat-header-cell *matHeaderCellDef>
                  Family Accum.
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyAccumulation !== null
                  ? (element.familyAccumulation | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Member">
                <th scope="col" mat-header-cell *matHeaderCellDef>Member</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyMemberLimit !== null
                  ? (element.familyMemberLimit | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="MemberAccum">
                <th scope="col" mat-header-cell *matHeaderCellDef>
                  Member Accum.
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyMemberAccumulation !== null
                  ? (element.familyMemberAccumulation | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Single">
                <th mat-header-cell *matHeaderCellDef>Single</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.singleLimit !== null
                  ? (element.singleLimit | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="SingleAccum">
                <th mat-header-cell *matHeaderCellDef>Single Accum.</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.singleAccumulation !== null
                  ? (element.singleAccumulation | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsMpp"></tr>

              <tr mat-row *matRowDef="let row; columns: displayedColumnsMpp"></tr>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion class="dependents-accordion" *ngIf="dataSourceOpp.length > 0">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Out Of Pocket Phase </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row" class="display-table">
            <table class="mar-t-1" mat-table [dataSource]="dataSourceOpp">
              <ng-container matColumnDef="Phase">
                <th scope="col" mat-header-cell *matHeaderCellDef>Phase</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.alias | uppercase }}
                </td>
              </ng-container>
              <ng-container matColumnDef="CapType">
                <th scope="col" mat-header-cell *matHeaderCellDef>Cap Type</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.type | titlecase }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Frequency">
                <th scope="col" mat-header-cell *matHeaderCellDef>Frequency</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.frequency | titlecase }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Family">
                <th scope="col" mat-header-cell *matHeaderCellDef>Family</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyLimit !== null
                  ? (element.familyLimit | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="FamilyAccum">
                <th scope="col" mat-header-cell *matHeaderCellDef>
                  Family Accum.
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyAccumulation !== null
                  ? (element.familyAccumulation | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Member">
                <th scope="col" mat-header-cell *matHeaderCellDef>Member</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyMemberLimit !== null
                  ? (element.familyMemberLimit | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="MemberAccum">
                <th scope="col" mat-header-cell *matHeaderCellDef>
                  Member Accum.
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.familyMemberAccumulation !== null
                  ? (element.familyMemberAccumulation | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Single">
                <th mat-header-cell *matHeaderCellDef>Single</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.singleLimit !== null
                  ? (element.singleLimit | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="SingleAccum">
                <th mat-header-cell *matHeaderCellDef>Single Accum.</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.singleAccumulation !== null
                  ? (element.singleAccumulation | currency : "USD")
                  : "n/a"
                  }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsOpp"></tr>

              <tr mat-row *matRowDef="let row; columns: displayedColumnsOpp"></tr>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div style="padding: 1rem;" *ngIf="capDeductibleMoop.capDeductibleAccumulation.length === 0">
        No Data Found
      </div>
    </div>
  </div>

  <div class="mat-row button-row">
    <div class="mat-12" fxLayoutAlign="end end">
      <button mat-button color="warn" class="secondary-btn" (click)="navigateBackToMemberSearch()">
        Cancel
      </button>
    </div>
  </div>
</div>